import React from 'react';
import classname from 'classname';
import { InlineSVG } from '../InlineSVG';
import css from './HelpBubble.scss';

import Tooltip from '../Tooltip/Tooltip';

const HelpBubble = ({
  message,
  url,
  size = 'medium', // "small" or "medium" (default)
  className,
}) => {
  const helpBubble = (
    <Tooltip
      variant="dark"
      title={message}
      up
      center
    >
      <div className={className}>
        <InlineSVG
          src="/img/icons/question-2.svg"
          className={classname(css.Icon, css[`Icon--${size}`])}
        />
      </div>
    </Tooltip>
  );

  if (typeof url === 'string') {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {helpBubble}
      </a>
    );
  }

  return helpBubble;
};

export default HelpBubble;
